export enum API_TYPES {
  AUGMENTED_OCASION_API = 'AUGMENTED_OCASION_API',
}

const developmentApi: Record<API_TYPES, string> = {
  AUGMENTED_OCASION_API: 'https://augmented-occasions-api-ch-elektra.os-dev.io/api',
}

const productionApi: Record<API_TYPES, string> = {
  AUGMENTED_OCASION_API: 'https://augmented-occasions-api-ch-bgroot.os-dev.io/api',
}

const config = Object.keys(API_TYPES).reduce((acc, type) => {
  const api =
    process.env.NODE_ENV !== 'development'
      ? process.env[`REACT_APP_${type}`] || productionApi[type as API_TYPES]
      : developmentApi[type as API_TYPES]
  return { ...acc, [type]: api }
}, {} as Record<API_TYPES, string>)

export default config
