import { WppButton, WppCard, WppSpinner, WppTypography } from '@wppopen/components-library-react'
import { useOs } from '@wppopen/react'
import { Link } from 'react-router-dom'

import { useFetchCreatives } from 'api/creatives/queries/useFetchCreatives'
import { Card } from 'components/card/Card'
import styles from 'pages/sitecoreAssetsReview/SitecoreAssetsReview.module.scss'

export const SitecoreAssetsReview = () => {
  const { osContext } = useOs()
  const { data, isLoading, isError } = useFetchCreatives({ params: { tenantId: osContext.tenant.id } })
  const viewURL = 'https://sitecoreocpdev.sitecoresandbox.cloud/en-us/waitingroom'

  return (
    <>
      <div className={styles.tool}>
        <WppTypography type="3xl-heading">Assets for Review</WppTypography>
        <Link to={viewURL} target="_blank">
          <WppButton variant="secondary">View all Assets</WppButton>
        </Link>
      </div>
      <WppCard className={styles.container}>
        <div className={styles.items}>
          {data.map(creative => (
            <Card key={creative.id} creative={creative} />
          ))}
        </div>
        {(isLoading || isError) && (
          <div className={styles.dataLoader}>
            {isLoading && <WppSpinner size="l" />}
            {isError && <WppTypography>Error</WppTypography>}
          </div>
        )}
      </WppCard>
    </>
  )
}
